import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import bgImage from "assets/images/vegas.jpg";


function Home() {
    return (
        <MKBox component="header" position="relative">
            {/* ... other components ... */}

            <MKBox
                display="flex"
                alignItems="center"
                minHeight="100vh"
                sx={{
                    backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
                        `${linearGradient(rgba(gradients.dark.main, 0.0), rgba(gradients.dark.state, 0.0))}, url(${bgImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                }}
            >
                <Container>
                    <Grid container style={{ marginTop: '100px' }}>
                        <Grid item xs={12} md={9} lg={6} flexDirection="column" >
                            <MKBox
                                sx={{
                                    backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent black background
                                    p: 5, // Padding inside the MKBox
                                    borderRadius: "20px", // Optional: if you want rounded corners
                                }}
                                >

                                <MKTypography
                                    variant="h1"
                                    color="white"
                                    mb={1}
                                    sx={({ breakpoints, typography: { size } }) => ({
                                        [breakpoints.down("md")]: {
                                            fontSize: size["3xl"],
                                        },

                                    })}
                                >
                                    Hi, I&apos;m Sharlene
                                </MKTypography>


                                <MKTypography variant="body1" color="white" opacity={0.7}  sx={{paddingTop:'10px'}}>
                                    Either intentionally or inadvertently, you&apos;ve stumbled upon my slice of the internet.
                                    Much like myself, this area is permanently under construction.
                                </MKTypography>
                                <MKTypography variant="body1" color="white" opacity={0.9}  sx={{paddingTop:'10px', paddingBottom:'10px'}}>
                                    Welcome.
                                </MKTypography>
                                <Stack direction="row" spacing={1} mt={3}>
                                    <MKButton variant="outlined" color="white">Professional</MKButton>
                                    <MKButton variant="outlined" color="white">
                                        Personal
                                    </MKButton>
                                </Stack>
                            </MKBox>
                        </Grid>
                    </Grid>
                </Container>
            </MKBox>
        </MKBox>
    );
}

export default Home;
